import './App.css';
import './antd.css';
import moment from 'moment';
import {
    Layout,
    Menu,
    Button,
    Typography,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Alert,
    message,
    Table,
    Radio,
    Space,
    center
} from 'antd';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoot, reportApiRoot } from "./Config";
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'
import * as qs from 'query-string';
import {
    LogoutOutlined,
    ProfileOutlined,
    DeleteOutlined,
    UserOutlined,
    CloseSquareOutlined
} from '@ant-design/icons';


var urlParams = qs.parse(window.location.search);
const { Header, Sider, Content, Footer } = Layout;
const { Option } = Select;
const { TextArea } = Input;

let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
});

restFul.interceptors.response.use((response) => response, (error) => {
    console.log(error);
    message.info(error.message);
    if (error.response.status == 401) {
        window.location.href = "/";
    }
});

var menuItems = [
    {
        key: 'application',
        icon: <UserOutlined />,
        label: (<a href={window.location.origin + "/freelancer/application?page=application&fuzzyId=" + urlParams.fuzzyId} target="_parent">Profile</a>),
        children: [
            {
                key: 'application-education',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=education-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">Education</a>)
            },
            {
                key: 'ell-proficiencies',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=ell-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">ELL</a>)
            },
            {
                key: 'language-proficiencies',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=language-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">Languages</a>)
            },
            {
                key: 'application-pricing',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=pricing&fuzzyId=" + urlParams.fuzzyId} target="_parent">Pricing</a>)
            },
            {
                key: 'application-addresses',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=addresses&fuzzyId=" + urlParams.fuzzyId} target="_parent">Address</a>)
            }
        ]
    },
    {
        key: 'dashboard',
        icon: <ProfileOutlined />,
        label: (<a href={window.location.origin + "/freelancer/dashboard?fuzzyId=" + urlParams.fuzzyId} target="_parent">Dashboard</a>)
    },
    {
        key: 'active-projects',
        icon: <ProfileOutlined />,
        label: (<a href={window.location.origin + "/freelancer/active-projects?fuzzyId=" + urlParams.fuzzyId} target="_parent">Active Projects</a>)
    },
    {
                key: 'work-orders',
                icon: <ProfileOutlined />,
                label: (<a href={window.location.origin + "/freelancer/work-orders?fuzzyId=" + urlParams.fuzzyId} target="_parent">Work Orders</a>)
    },
    {
        key: 'completed-projects',
        icon: <ProfileOutlined />,
        label: (<a href={window.location.origin + "/freelancer/completed-projects?fuzzyId=" + urlParams.fuzzyId} target="_parent">Completed Projects</a>)
    },
    {
        key: 'documents',
        icon: <ProfileOutlined />,
        label: (<a href={window.location.origin + "/freelancer/documents?fuzzyId=" + urlParams.fuzzyId} target="_parent">Documents</a>)
    },
    {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: (<a href={window.location.origin + "/logout"} target="_parent">Logout</a>)
    }
];

const languageCompetency = ["Beginner/GCSE", "Proficient/A level", "Fluent/Degree", "Native Speaker"];
const subjectCompetency = ["Primary", "Secondary", "Postsecondary"];
const ellCompetency = ["Primary", "Secondary", "Postsecondary"];



export default function Application(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [showAfterSubmit, setShowAfterSubmit] = useState({"profile":"none"});
    const [form] = Form.useForm();
    const [bankForm] = Form.useForm();
    const [applicationData, setApplicationData] = useState({});
    const [formStatus, setFormStatus] = useState(null);
    const [interestList, setInterestList] = useState([]);
    const [stemList, setStemList] = useState([]);
    const [nonStemList, setNonStemList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [citationList, setCitationList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [userPricingValues, setUserPricingValues] = useState([]);
    const [userPricing, setUserPricing] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [softwareList, setSoftwareList] = useState([]);
    const [userAddresses, setUserAddresses] = useState([]);
    const [userLanguage, setUserLanguage] = useState([]);
    const [userEll, setUserEll] = useState([]);
    const [userSubject, setUserSubject] = useState([]);
    const [ellList, setEllList] = useState([]);
    const [userAppFuzzy, setUserAppFuzzy] = useState(null)
    const [bankDetails, setBankDetails] = useState({});
    const menuClicked = (e) => {
        console.log(e);
        window.location.href = '/freelancer/' + e.key;
    };

    console.log(urlParams);
    const params = useParams();
    var freelanceAuth = store("pubkit_auth");
    var headers = { ...freelanceAuth };
     
    var freelanceAuth = store("pubkit_auth");
    var headers = { ...freelanceAuth };

    var userCapabilities = store("pubkit_role");
    const isManager = (userCapabilities.includes("freelance_manager") ? true : false);
    console.log("isManager", isManager);

    if (isManager) {
        menuItems[menuItems.length-1] =  {
                key: 'Close',
                icon: <CloseSquareOutlined />,
                label: (<a onClick={e => window.close()} href="#">Close</a>)
            }
    }

    const onFinish = (values) => {
        values["status"] = "submitted";
        console.log('Received values of form: ', values);
        if (values["stems"] == undefined) { values["stems"] = []; }
        if (values["nonStems"] == undefined) { values["nonStems"] = []; }
        if (values["interests"] == undefined) { values["interests"] = []; }
        if (values["subjects"] == undefined) { values["subjects"] = []; }
        if (values["citations"] == undefined) { values["citations"] = []; }
        if (values["skills"] == undefined) { values["skills"] = []; }
        if (values["softwares"] == undefined) { values["softwares"] = []; }

        if (Array.isArray(values["stems"]) == false) { values["stems"] = values["stems"].split("~~~"); }
        if (Array.isArray(values["nonStems"]) == false) values["nonStems"] = values["nonStems"].split("~~~");
        if (Array.isArray(values["interests"]) == false) values["interests"] = values["interests"].split("~~~");
        if (Array.isArray(values["subjects"]) == false) values["subjects"] = values["subjects"].split("~~~");
        if (Array.isArray(values["citations"]) == false) values["citations"] = values["citations"].split("~~~");
        if (Array.isArray(values["skills"]) == false) values["skills"] = values["skills"].split("~~~");
        if (Array.isArray(values["softwares"]) == false) values["softwares"] = values["softwares"].split("~~~");

        values["status"] = "submitted";
        axios.post(apiRoot + `submissions/freelancer/api/user/submit-application`, values, { headers })
            .then(res => {
                console.log(res);
                message.info("Application is saved successfully...");
            }).catch(function (error) {
                console.log(error);
                message.info("Oops. Unable to save your application");
            });
            setShowAfterSubmit({"profile":"block"});
    };

    const findIdsOnly = (input) =>
    {
        var returnValues = [];
        input.forEach(each => returnValues.push(each.id + ""));
        return returnValues;
    }

    useEffect(() => {
        
        console.log(params);
        var freelanceAuth = store("pubkit_auth");
        var headers = { ...freelanceAuth };
        axios.post(reportApiRoot + `reportsmicro/reports/approved_queries/bank-details-by-user`, {where: {}}, {headers})
    .then(res => {
        console.log(res.data[0]['fuzzy_id']);
        if(res.data.length>0){
          setUserAppFuzzy(res.data[0]['fuzzy_id'])
          setBankDetails({
            user: res.data[0]["full_name"],
            bank_account_name: res.data[0]["bank_account_name"],
            bank_name: res.data[0]["bank_name"],
            bank_branch: res.data[0]["bank_branch"],
            bank_account_number: res.data[0]["bank_account_number"],
            pan_number: res.data[0]["pan_number"],
            ifsc_rtgs_code: res.data[0]["ifsc_rtgs_code"],
            fuzzy_id: res.data[0]['fuzzy_id'],
            country: res.data[0]["country"]
        })
        bankForm.setFieldsValue({
          user: res.data[0]["full_name"],
          bank_account_name: res.data[0]["bank_account_name"],
          bank_name: res.data[0]["bank_name"],
          bank_branch: res.data[0]["bank_branch"],
          bank_account_number: res.data[0]["bank_account_number"],
          pan_number: res.data[0]["pan_number"],
          ifsc_rtgs_code: res.data[0]["ifsc_rtgs_code"]
      })
        }
        
    }).catch(function (error) {
        console.log(error);
    }); 
        if((urlParams.fuzzyId==undefined) || (urlParams.fuzzyId=="undefined"))
        {
            var apiUrl = apiUrl = `${apiRoot}submissions/freelancer/api/user/find-application?fuzzyId=` + urlParams.fuzzyId;
            axios.get(apiUrl, { headers }).then(res => {
                window.location.href = window.location.origin + "/freelancer/application?page=application&fuzzyId=" + res.data["fuzzyId"];
            });
        }


        axios.get(`${apiRoot}submissions/freelancer/public/countries`, { headers }).then(res => {
            res.data = res.data.sort(function (a, b) { return a.name > b.name ? 1 : -1; });
            setCountryList(res.data);
        });

        var apiUrl = apiUrl = `${apiRoot}submissions/freelancer/api/user/find-application?fuzzyId=` + urlParams.fuzzyId;
        axios.get(apiUrl, { headers }).then(res => {
            console.log(res.data)
            const allSkills = ((res.data.allSkills!=null) ? res.data.allSkills : []);
            var serverData = Object.assign({}, res.data, JSON.parse(res.data.payload));
            serverData["interests"] = findIdsOnly(allSkills.filter(each => each.genre=="interest"));
            serverData["stems"] = findIdsOnly(allSkills.filter(each => each.genre=="stem"));
            serverData["nonStems"] = findIdsOnly(allSkills.filter(each => each.genre=="non-stem"));
            serverData["subjects"] = findIdsOnly(allSkills.filter(each => each.genre=="subject"));
            serverData["citations"] = findIdsOnly(allSkills.filter(each => each.genre=="citation"));
            serverData["skills"] = findIdsOnly(allSkills.filter(each => each.genre=="skill"));
            serverData["softwares"] = findIdsOnly(allSkills.filter(each => each.genre=="software"));

            setFormStatus(res.data.status);
            setApplicationData({ ...serverData });
            console.log("applicationData", serverData);
            form.setFieldsValue(serverData);
            console.log(serverData);
            urlParams["fuzzyId"] = res.data["fuzzyId"];
        });

        axios.get(`${apiRoot}submissions/freelancer/public/skills`, { headers }).then(res => {
            setInterestList(res.data.filter(record => record["genre"] == "interest"));
            setNonStemList(res.data.filter(record => record["genre"] == "non-stem"));
            setStemList(res.data.filter(record => record["genre"] == "stem"));
            setSubjectList(res.data.filter(record => record["genre"] == "subject"));
            setCitationList(res.data.filter(record => record["genre"] == "citation"));
            setSkillList(res.data.filter(record => record["genre"] == "skill"));
            setSoftwareList(res.data.filter(record => record["genre"] == "software"));
            setEllList(res.data.filter(record => record["genre"] == "ell"));
        });


        axios.get(`${apiRoot}submissions/freelancer/api/user/pricing?fuzzyId=` + urlParams.fuzzyId, { headers }).then(res => {
            var rowData = res.data["skills"].map(item => ({
                ...res.data["pricing"].find(({ skillId }) => item.id == skillId),
                ...item,
            }));

            var pricingValues = {};
            rowData.forEach(function (item) {
                item["unit_" + item["id"]] = item["unit"];
                item["currency_" + item["id"]] = item["currency"];
                item["rate_" + item["id"]] = item["rate"];
                pricingValues = Object.assign(pricingValues, item);
                return item;
            });
            console.log("userPricing", rowData);
            console.log("pricingValues", pricingValues);
            setUserPricingValues(pricingValues);
            form.setFieldsValue(pricingValues);
            setUserPricing(rowData);
        });

        if (urlParams["page"] == "addresses") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/user/addresses?fuzzyId=` + urlParams.fuzzyId;;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setUserAddresses(res.data);
            });
        }

        if ((urlParams["page"] == "education-proficiencies") || (urlParams["page"] == "ell-proficiencies") || (urlParams["page"] == "language-proficiencies")) {
            axios.get(`${apiRoot}submissions/freelancer/public/skills`, { headers }).then(res => {
                setEllList(res.data.filter(record => record["genre"] == "ell"));
                setSubjectList(res.data.filter(record => record["genre"] == "subject"));
                setLanguageList(res.data.filter(record => record["genre"] == "language"));
            });

            axios.get(`${apiRoot}submissions/freelancer/api/user/skills?fuzzyId=${urlParams["fuzzyId"]}`, { headers }).then(res => {
                setUserEll(res.data.filter(record => record["genre"] == "ell"));
                setUserSubject(res.data.filter(record => record["genre"] == "subject"));
                setUserLanguage(res.data.filter(record => record["genre"] == "language"));
            });
        }


    }, []);


    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Layout className="site-layout">
                <Sider trigger={null} collapsible collapsed={collapsed} width={250} height={1000}>
                <div><img width="250" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>
                    <Menu
                        defaultOpenKeys={["application"]}
                        theme="dark"
                        mode="inline"
                        onClick={(e) => menuClicked(e)}
                        items={menuItems}
                    />
                </Sider>

                {(urlParams["page"] == "application") && showApplication()}
                {(urlParams["page"] == "pricing") && showPricing()}
                {(urlParams["page"] == "addresses") && renderAddresses()}
                {(urlParams["page"] == "education-proficiencies") && renderEducationProficiencies()}
                {(urlParams["page"] == "ell-proficiencies") && renderEllProficiencies()}
                {(urlParams["page"] == "language-proficiencies") && renderLanguageProficiencies()}


            </Layout>
            <Footer style={{ textAlign: 'center' }}><div className="myfooter"><a href="https://docs.google.com/document/d/e/2PACX-1vSssehMeX9FNAP534Q-ilH4XaI-wc__B5ASKIsGye90sV3YGatOhuP-KjDwPymJQ6o5Bpiu6VyNCsmY/pub">Terms and Conditions</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://docs.google.com/document/d/e/2PACX-1vSR8nGaX8FiG9JNheeZG6-4INv9vPh1uStfYqEomhlliL1hCiRFgR2ZCFVVUGMebLmIn7dgi8z6GMob/pub">Privacy Policy</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://newgen.co/aboutus">Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</a></div></Footer>
        </Layout>
    );



    function showApplication() {


        const onFinishFailed = (e) =>
        {
            if(e.errorFields)
            {
                message.info("Invalid data. Please fix.");
            }
        }
    
        return (
            <Content
                style={{
                    margin: '10px 16px',
                    padding: 0,
                    width: 800,
                    overflow: "scroll"
                }}>
                <div className="searchfilter">
                    <div className="searchfilterheader">
                        <Typography>Please complete this web form (including the proficiencies screens). If you wish to upload your CV/resume, samples of your work, or any other documents, please visit the <a href={window.location.origin + "/freelancer/documents?fuzzyId=" + urlParams["fuzzyId"]}>the documents</a> section.</Typography>
                    </div>
                    <Form
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 8, }}
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={applicationData}>
                        
                        <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="lastName" label="Last Name">
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label="Email" extra="Registered Email for primary communication. This can't be modified.">
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item name="alternateEmail" label="Alternate Email">
                            <Input />
                        </Form.Item>

                        <Form.Item name="country" label="Country" extra="Country-specific document(s) may be required; for example, a W-9 for US residents" rules={[{ required: true }]}>
                            <Select showSearch showArrow={false}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {countryList.map((eachItem) => (<Option value={eachItem.name}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="website" label="Website">
                            <Input />
                        </Form.Item>
                        <Form.Item name="twitter" label="Twitter">
                            <Input />
                        </Form.Item>
                        <Form.Item name="linkedin" label="LinkedIn">
                            <Input />
                        </Form.Item>
                        <Form.Item name="freelancer_type" label="Is your company" value={"onePerson"} rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value="onePerson"> one person </Radio>
                                <Radio value="team"> a team </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="vat" label="VAT number (if applicable)">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="membership"
                            label="Professional body membership (if applicable)">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="interests"
                            label="Are you interested in working for" rules={[{ required: true }]}>
                            <Select mode="tags"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {interestList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="newEmployee"
                            label="Were you referred by a Newgen employee? If Yes, please provide name here">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="skills" label="Services offered (Multiple entries are allowed)" rules={[{ required: true, message: "Selection of at least one service is required" }]}>
                            <Select
                                showSearch
                                mode="tags"
                                showArrow={false}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {skillList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="nonStems" label="Academic subject specialisms non-STEM">
                            <Select showSearch mode="tags"
                                showArrow={false} optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {nonStemList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="stems" label="Academic subject specialisms STEM" >
                            <Select mode="tags"
                                placeholder={props.placeholder} showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                notFoundContent={null}>
                                {stemList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="otherDetails"
                            label="If you selected 'other', please supply details below">
                            <TextArea
                                autoSize={{ minRows: 1, maxRows: 2 }} />
                        </Form.Item>

                        <Form.Item
                            name="citations"
                            label="Documentation/style book familiarity">
                            <Select mode="tags" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {citationList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="softwares"
                            label="Specialist tools knowledge (check all that apply)">
                            <Select mode="tags" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {softwareList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="remarks"
                            label="Please add any further relevant information (if any)" >
                            <TextArea
                                autoSize={{ minRows: 1, maxRows: 2 }} />
                        </Form.Item>

                        <Form.Item name="experience" label="Publishing Experience summary" extra="(Completing the above is only mandatory if you are applying for work in Education or ELL; if so, please provide a brief summary of any education sector publishing experience, and also list recent clients and projects if possible.)">
                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                        </Form.Item>
                        <center>
                            <Space>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">SUBMIT</Button> &#x02003;
                                </Form.Item>
                            </Space>
                        </center>
                    </Form>
                    <div style={{marginBottom:"0px", display:showAfterSubmit["profile"]}} className="searchfilterheader">
                        <Typography>Please use the links below or on the menu at left to tell us about your <b>Education</b>, <b>ELL</b>, and/or <b>languages</b> skills/training and detail, if you wish, your <b>pricing</b> preferences and <b>address</b>.</Typography>
                    </div>
                </div>
                <div><small>Please complete all fields that apply to you. Asterisks indicate mandatory fields, but the more information you provide, the more likely you are to get work. If you want to upload any relevant documents (a CV or resume or sample, for example) you can do so in the Documents section.</small></div>
                <table style={{ width: "100%", marginTop: 20 }}>
                    <tbody>
                        <tr>
                            <td><Button type="link" href={window.location.origin + "/freelancer/documents?fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;Documents</Button></td>
                            <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=education-proficiencies&fuzzyId=" + urlParams.fuzzyId}>Education Proficiencies&gt;&gt;</Button></td>
                        </tr>
                    </tbody>
                </table>
            </Content>
        )
    }


    function showPricing() {
        const onPricingFinish = (values) => {
            console.log(values);
            var payload = {};
            for (var prop in values) {
                const keySplit = prop.split("_");
                if (payload[keySplit[1]] == undefined) { payload[keySplit[1]] = { "status": "SUBMITTED", "skillId": keySplit[1] }; }
                payload[keySplit[1]][keySplit[0]] = values[prop];
            }

            var payloadList = [];
            for (var prop in payload) {
                payloadList.push(payload[prop]);
            }

            axios.post(apiRoot + `submissions/freelancer/api/user/pricing/save?fuzzyId=`+urlParams.fuzzyId, payloadList, { headers })
                .then(res => {
                    message.info(res.data.message);
                    console.log(res);
                }).catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <Content
                style={{
                    margin: '10px 16px',
                    padding: 0,
                    width: 800,
                    overflow: "scroll"
                }}>

                {(userPricing.length == 0) && (<Typography>You haven't applied any skill in the application. Please add your freelance services</Typography>)}

                <Card style={{ width: 800 }} title="Pricing Details" bordered={false}>

                    <Form layout="vertical" form={form} name="register"
                        onFinish={onPricingFinish}
                        initialValues={userPricingValues} scrollToFirstError>
                        {userPricing.map((eachItem) => (
                            <Row justify="space-between" key={'row-' + eachItem.id}>
                                <Col span={8}><p style={{marginTop:"5%"}}><b>Service:</b> <br/>{eachItem.name}</p></Col>
                                <Col span={4}>
                                    <Form.Item name={"unit_" + eachItem.id} label="Unit"
                                        rules={[{ required: true }]}>
                                        <Select >
                                            <Option value="PAGE">PER PAGE</Option>
                                            <Option value="HOUR">PER HOUR</Option>
                                            <Option value="FIGURE">PER FIGURE</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={"rate_" + eachItem.id} label="Rate" rules={[{ required: true }]}>
                                        <InputNumber min={0} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={"currency_" + eachItem.id} label="Currency"
                                        rules={[{ required: true }]}>
                                        <Select>
                                            <Option value="USD">USD</Option>
                                            <Option value="GBP">GBP</Option>
                                            <Option value="INR">INR</Option>
                                            <Option value="SAR">SAR</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        <Form.Item style={{ textAlign: "right" }}>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Form>
                </Card>
                <p><small>These details are optional. If you do not wish to submit, you can skip.</small></p>

                <table style={{ width: 800, marginTop: 20 }}>
                    <tr>
                        <td><Button type="link" href={"application?page=language-proficiencies&fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;Language Proficiencies</Button></td>
                        <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=addresses&fuzzyId=" + urlParams.fuzzyId}>Address&gt;&gt;</Button></td>
                    </tr>
                </table>
            </Content>
        )
    }


    function renderAddresses() {

        const deleteAddress = (record) => {
            axios.delete(`${apiRoot}submissions/freelancer/api/user/address/delete/` + record.fuzzyId, { headers }).then(res => {
                window.location.href = window.location.href;
            });
        }

        const addUserAddress = (values) => {
            restFul.post("submissions/freelancer/api/user/add/address?fuzzyId="+urlParams["fuzzyId"], values, { headers }).then(res => {
                console.log(res.data);
                window.location.href = window.location.href;
            });
        }
        const addBankDetails = (e) => {
            console.log(e)
    console.log(store.get("freelance_user"))
    let userDetails = store.get("pubkit_user") == null ? store.get("freelance_user"): store.get("pubkit_user")
    let payload = {
            "status": "SUBMITTED",
            "user_id": userDetails.id,
            "company": "NKW",
            "country": "India",
            "fuzzy_id": userAppFuzzy,
            "first_name": userDetails.first_name,
            "last_name": userDetails.first_name,
            "bank_account_name": e.bank_account_name,
            "bank_account_number": e.bank_account_number,
            "bank_name": e.bank_name,
            "ifsc_rtgs_code": e.ifsc_rtgs_code,
            "pan_number": e.pan_number,
            "bank_branch": e.bank_branch,
            "user_type": "SUPPLIER"
        }
            restFul.post("submissions/freelancer/api/bank-details/upsert", payload, { headers }).then(res => {
                console.log(res.data);
                if(res.data.status == 200){
                    message.success("Bank details updated");
                    setTimeout(function(){
                        window.location.reload();
                     }, 2000); 
                    
                }
                
            }).catch(error=>{
                message.error(error.data);
            });
        }

        return (
            <Content
                style={{
                    margin: '10px 16px',
                    padding: 0,
                    width: 800,
                    overflow: "scroll"
                }}>
                <div className="searchfilter">
                    <div className="searchfilterheader">Add New Address</div>
                    <Form
                        labelCol={{ span: 11 }}
                        wrapperCol={{ span: 8, }}
                        name="control-hooks"
                        onFinish={addUserAddress}>

                        <Row style={{ width: "100%" }}><Col style={{ width: "33%" }}>
                            <Form.Item
                                label="Address Line 1"
                                name="addressLine1"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }} />
                            </Form.Item>
                        </Col><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="Address Line 2"
                                    name="addressLine2"
                                    rules={[{ required: true }]}>
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="Address Line 3"
                                    name="addressLine3">
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col></Row><Row><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[{ required: true }]}>
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="State"
                                    name="state"
                                    rules={[{ required: true }]}>
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[{ required: true }]}>
                                    <Select style={{ width: "150%" }}>
                                        {countryList.map((eachItem) => (<Option key={eachItem.name}>{eachItem.name}</Option>))}
                                    </Select>
                                </Form.Item>
                            </Col></Row><Row><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="Zip"
                                    name="zip"
                                    rules={[{ required: true }]}>
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col><Col style={{ width: "33%" }}>
                                <Form.Item
                                    label="Phone"
                                    name="phone">
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                            </Col><Col style={{ width: "33%" }}>
                                <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                                    <Select style={{ width: "150%" }}>
                                        <Option value="HOME">HOME</Option>
                                        <Option value="WORK">WORK</Option>
                                        <Option value="OTHER">OTHER</Option>
                                    </Select>
                                </Form.Item>
                            </Col></Row>
                        <center>
                            <Space>
                                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                                    <Button type="primary" htmlType="submit">SUBMIT</Button>
                                </Form.Item>
                            </Space>
                        </center>
                    </Form>
                </div>

                <Table pagination={false}  rowKey="id" columns={[
                    {
                        title: 'Address',
                        dataIndex: 'addressLine1',
                        render: (e, row)=> {
                            let addressLine1 = row["addressLine1"]
                            let addressLine2 = row['addressLine2'] == null? "":", " + row['addressLine2']
                            let addressLine3 = row['addressLine3']== null? "":", " + row['addressLine3']
                            return addressLine1+addressLine2+addressLine3
                        }
                        
                    },
                    // {
                    //     title: 'Country',
                    //     dataIndex: 'country'
                    // },
                    // {
                    //     title: 'Zip',
                    //     dataIndex: 'zip'
                    // },
                    {
                        title: 'Phone',
                        dataIndex: 'phone'
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'createdAt',
                        render: (value, row) => {
                            if(value == null) return moment(row["updatedAt"]).format("lll")
                            return moment(value).format("lll")
                        }
                    },
                    {
                        title: 'Delete',
                        render: (record) => (
                            <Button type="link" onClick={(e) => deleteAddress(record)}><DeleteOutlined /></Button>
                        )
                    }
                ]} dataSource={userAddresses} />
                <div className="searchfilter">
                    <div className="searchfilterheader">Update Bank Details</div>
                    <Form
                        labelCol={{ span: 11 }}
                        wrapperCol={{ span: 8, }}
                        name="control-hooks"
                        onFinish={addBankDetails}
                        form={bankForm}>

                        <Row style={{ width: "100%" }}><Col style={{ width: "70%", marginRight:"auto" }}>
                            <Form.Item
                                label="Your Name in Bank Account"
                                name="bank_account_name"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }} />
                            </Form.Item>
                            <Form.Item
                                label="Bank Name"
                                name="bank_name"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }} />
                            </Form.Item>
                            <Form.Item
                                label="Bank Account Number"
                                name="bank_account_number"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }}/>
                            </Form.Item>
                            <Form.Item
                                label="Bank Branch"
                                name="bank_branch"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }} />
                            </Form.Item>
                            {bankDetails.country == "India" ?<Form.Item
                                label="PAN Number"
                                name="pan_number"
                                rules={[{ required: true }]}>
                                <Input style={{ width: "150%" }} />
                            </Form.Item> : null}
                        
                                <Form.Item
                                    label="IFSC Code"
                                    name="ifsc_rtgs_code"
                                    rules={[{ required: true }]}>
                                    <Input style={{ width: "150%" }} />
                                </Form.Item>
                          
                            </Col></Row><Row></Row>
                        <center>
                            <Space>
                                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                                    <Button type="primary" htmlType="submit">SUBMIT</Button>
                                </Form.Item>
                            </Space>
                        </center>
                    </Form>
                </div>
                <table style={{ width: "100%", marginTop: 20 }}>
                    <tr>
                        <td><Button type="link" href={"application?page=pricing&fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;Pricing</Button></td>
                        <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=application&fuzzyId=" + urlParams.fuzzyId}>Application&gt;&gt;</Button></td>
                    </tr>
                </table>

            </Content>
        )
    }

    function renderEducationProficiencies() {
        const deleteUserSkill = (record) => {
            axios.delete(`${apiRoot}submissions/freelancer/api/user/skill/delete/` + record.fuzzyId, { headers }).then(res => {
                window.location.href = window.location.href;
            });
        }

        const addUserSkills = (values, genre) => {
            console.log(values, genre);
            values["skill"] = genre;
            restFul.post("submissions/freelancer/api/user/skill/add?fuzzyId="+urlParams["fuzzyId"], values, { headers }).then(res => {
                console.log(res.data);
                window.location.href = window.location.href;
            });
        }


        return (
            <Content style={{ margin: '10px 16px', padding: 0, width: "800px", overflow: "scroll" }}>
                <div className="filtersmall">
                    <div className="searchfilterheader">Education Subject Specialisms</div>
                    <Form name="control-hooks" onFinish={(e) => addUserSkills(e,"subject")} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }} >
                        <Form.Item label="Education Subject Specialisms" name="skillId" rules={[{ required: true }]}  placeholder="Search to Subjects">
                            <Select showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {subjectList.filter(eachItem => eachItem.forAll==true).map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Competency" name="competency" rules={[{ required: true }]}>
                        <Select allowClear>
                      {subjectCompetency.map(each => 
                        <Option value={each}>{each}</Option>
                      )}
                    </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 10 }}>
                            <Button type="primary" htmlType="submit">SUBMIT</Button>
                        </Form.Item>
                    </Form>
                </div>
                <Table pagination={false} style={{ width: "800px", marginTop: 20 }} rowKey="id" columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Competency',
                        dataIndex: 'competency'
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'createdAt',
                        render: (value) => (
                            <span>{moment(value).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Delete',
                        render: (record) => (
                            <Button type="link" onClick={(e) => deleteUserSkill(record)}><DeleteOutlined /></Button>
                        )
                    }
                ]} dataSource={userSubject} />

                <table style={{ width: "50%", marginTop: 20 }}>
                    <tr>
                        <td style={{ textAlign: "left" }}><Button type="link" href={"application?page=application&fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;Application</Button></td>
                        <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=ell-proficiencies&fuzzyId=" + urlParams.fuzzyId}>ELL Proficiencies&gt;&gt;</Button></td>
                    </tr>
                </table>

            </Content>
        )
    }



    function renderEllProficiencies() {
        const deleteUserSkill = (record) => {
            axios.delete(`${apiRoot}submissions/freelancer/api/user/skill/delete/` + record.fuzzyId, { headers }).then(res => {
                window.location.href = window.location.href;
            });
        }

        const addUserSkills = (values, genre) => {
            console.log(values, genre);
            values["skill"] = genre;
            restFul.post("submissions/freelancer/api/user/skill/add?fuzzyId="+urlParams["fuzzyId"], values, { headers }).then(res => {
                console.log(res.data);
                window.location.href = window.location.href;
            });
        }


        return (
            <Content style={{ margin: '10px 16px', padding: 0, width: 800, overflow: "scroll" }}>
                <div className="filtersmall">
                    <div className="searchfilterheader">ELL</div>
                    <Form name="control-hooks" onFinish={(e) => addUserSkills(e,"ell")} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} >
                        <Form.Item label="ELL Subject Specialisms" name="skillId" rules={[{ required: true }]}  placeholder="Search to ELL">
                            <Select showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {ellList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Competency" name="competency" rules={[{ required: true }]}>
                        <Select allowClear>
                      {ellCompetency.map(each => 
                        <Option value={each}>{each}</Option>
                      )}
                    </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 10 }}>
                            <Button type="primary" htmlType="submit">SUBMIT</Button>
                        </Form.Item>
                    </Form>
                </div>
                

                <Table pagination={false}  style={{ width: "800px", marginTop: 20 }} rowKey="id" columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Competency',
                        dataIndex: 'competency'
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'createdAt',
                        render: (value) => (
                            <span>{moment(value).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Delete',
                        render: (record) => (
                            <Button type="link" onClick={(e) => deleteUserSkill(record)}><DeleteOutlined /></Button>
                        )
                    }
                ]} dataSource={userEll} />


                <table style={{ width: "50%", marginTop: 20 }}>
                    <tr>
                        <td style={{ textAlign: "left" }}><Button type="link" href={"application?page=education-proficiencies&fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;Education Proficiencies</Button></td>
                        <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=language-proficiencies&fuzzyId=" + urlParams.fuzzyId}>Language Proficiencies&gt;&gt;</Button></td>
                    </tr>
                </table>

            </Content>
        )
    }



    function renderLanguageProficiencies() {
        const deleteUserSkill = (record) => {
            axios.delete(`${apiRoot}submissions/freelancer/api/user/skill/delete/` + record.fuzzyId, { headers }).then(res => {
                window.location.href = window.location.href;
            });
        }

        const addUserSkills = (values, genre) => {
            console.log(values, genre);
            values["skill"] = genre;
            restFul.post("submissions/freelancer/api/user/skill/add?fuzzyId="+urlParams["fuzzyId"], values, { headers }).then(res => {
                console.log(res.data);
                window.location.href = window.location.href;
            });
        }


        return (
            <Content style={{ margin: '10px 16px', padding: 0, width: 800, overflow: "scroll" }}>
                <div className="filtersmall">
                    <div className="searchfilterheader">Known Language</div>
                    <Form name="control-hooks" onFinish={(e) => addUserSkills(e,"language")} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} >
                        <Form.Item label="Language" name="skillId" rules={[{ required: true }]}  placeholder="Search to Language">
                            <Select showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {languageList.map((eachItem) => (<Option key={eachItem.id}>{eachItem.name}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Competency" name="competency" rules={[{ required: true }]}>
                        <Select allowClear>
                        {languageCompetency.map(each => 
                            <Option value={each}>{each}</Option>
                        )}
                        </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 10 }}>
                            <Button type="primary" htmlType="submit">SUBMIT</Button>
                        </Form.Item>
                    </Form>
                </div>
                <p><small>Incl. English skill level.</small></p>

                <Table pagination={false}  style={{ width: "800px", marginTop: 20 }} rowKey="id" columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Competency',
                        dataIndex: 'competency'
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'createdAt',
                        render: (value) => (
                            <span>{moment(value).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Delete',
                        render: (record) => (
                            <Button type="link" onClick={(e) => deleteUserSkill(record)}><DeleteOutlined /></Button>
                        )
                    }
                ]} dataSource={userLanguage} />

                <table style={{ width: "50%", marginTop: 20 }}>
                    <tr>
                        <td style={{ textAlign: "left" }}><Button type="link" href={"application?page=ell-proficiencies&fuzzyId=" + urlParams.fuzzyId}>&lt;&lt;ELL Proficiencies</Button></td>
                        <td style={{ textAlign: "right" }}><Button type="link" href={"application?page=pricing&fuzzyId=" + urlParams.fuzzyId}>Pricing&gt;&gt;</Button></td>
                    </tr>
                </table>

            </Content>
        )
    }

}