import './App.css';
import './antd.css';
import {
    DeleteOutlined,
    LogoutOutlined,
    CloudUploadOutlined,
    UserOutlined,
    CommentOutlined,
    CloudDownloadOutlined,
    ContainerOutlined,
    ProfileOutlined,
    CloseSquareOutlined,
    DownloadOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    DatePicker,
    Button,
    Col,
    Descriptions,
    Card,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Typography,
    message
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, myBucket, AWS, reportApiRoot } from "./Config";
import * as qs from 'query-string';

const { Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const columns = [
    {
        key: "id",
        title: 'Name',
        dataIndex: 'id'
    },
    {
        key: "created_at",
        title: 'Created At',
        dataIndex: 'created_at'
    }
];


const { Header, Sider, Content, Footer } = Layout;
const { Option } = Select;

const downloadS3File = (record) => {
    if (record.filePath == undefined) record["filePath"] = record["filepath"];
    const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5) });
    window.location.href = url;
}


let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
});

restFul.interceptors.response.use((response) => response, (error) => {
    console.log(error);
    message.info(error.message);
    if (error.response.status == 401) {
        window.location.href = "/";
    }
});

const formatDate = (data, field) => {
    console.log("data", data);
    for (var i = 0; i < data.length; i++) {
        if (data[i][field] == undefined) continue;
        data[i][field] = moment(data[i][field]).format("lll");
    }
    return data;
}



export default function Freelancer(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const [userDocumentStatusModal, setUserDocumentStatusModal] = useState(false);
    const params = useParams();
    const [freelancers, setFreelancers] = useState([]);
    const [userDocuments, setUserDocuments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [supplier, setSupplier] = useState(0);
    const [workOrders, setWorkOrders] = useState([]);
    const [workOrderID, setWorkOrderID] = useState();
    const [userInfo, setUserInfo] = useState({ "application": {}, "user": {}, "user_unavail": [], "status": "To be submitted", "userSkills":[] });
    const [currentObject, setCurrentObject] = useState({});
    const [showProjectFileUpload, setShowProjectFileUpload] = useState("none");
    const [userScore, setUserScore] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [theId, setTheId] = useState(null);
    const [projectFiles, setProjectFiles] = useState([]);
    const [projectStatusModal, setProjectStatusModal] = useState(false);
    const [userNotesModalMessage, setUserNotesModalMessage] = useState("");
    const [userNotesModalShow, setUserNotesModalShow] = useState(false);
    
    const urlParams = qs.parse(window.location.search);
    console.log("urlParams", urlParams);


    
    var userCapabilities = store("pubkit_role");
    const isManager = (userCapabilities.includes("freelance_manager") ? true : false);
    console.log("isManager", isManager);

    var menuItems = [
        {
            key: 'application',
            icon: <UserOutlined />,
            label: (<a href={"application?fuzzyId=" + urlParams['fuzzyId'] + "&page=application"} target="_parent">Application</a>),
            children:[
            {
                key: 'application-education',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=education-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">Education</a>)
            },
            {
                key: 'ell-proficiencies',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=ell-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">ELL</a>)
            },
            {
                key: 'language-proficiencies',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=language-proficiencies&fuzzyId=" + urlParams.fuzzyId} target="_parent">Languages</a>)
            },
            {
                key: 'application-pricing',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=pricing&fuzzyId=" + urlParams.fuzzyId} target="_parent">Pricing</a>)
            },
            {
                key: 'application-addresses',
                icon: <UserOutlined />,
                label: (<a href={window.location.origin + "/freelancer/application?page=addresses&fuzzyId=" + urlParams.fuzzyId} target="_parent">Address</a>)
            }
        ]
        },
        {
            key: 'dashboard',
            icon: <ProfileOutlined />,
            label: (<a href={window.location.origin + "/freelancer/dashboard?fuzzyId=" + urlParams.fuzzyId} target="_parent">Dashboard</a>)
        },
        {
            key: 'active-projects',
            icon: <ProfileOutlined />,
            label: (<a href={window.location.origin + "/freelancer/active-projects?fuzzyId=" + urlParams.fuzzyId} target="_parent">Active Projects</a>)
        },
        // {
        //     key: 'work-orders',
        //     icon: <ProfileOutlined />,
        //     label: (<a href={window.location.origin + "/freelancer/work-orders?fuzzyId=" + urlParams.fuzzyId} target="_parent">Work Orders</a>)
        // },
        {
            key: 'completed-projects',
            icon: <ProfileOutlined />,
            label: (<a href={window.location.origin + "/freelancer/completed-projects?fuzzyId=" + urlParams.fuzzyId} target="_parent">Completed Projects</a>)
        },
        {
            key: 'documents',
            icon: <ContainerOutlined />,
            label: (<a href={window.location.origin + "/freelancer/documents?fuzzyId=" + urlParams.fuzzyId} target="_parent">Documents</a>)
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: (<a href={window.location.origin + "/logout"} target="_parent">Logout</a>)
        }
    ];

    if (isManager) {
        menuItems[menuItems.length-1] =  {
                key: 'Close',
                icon: <CloseSquareOutlined />,
                label: (<a onClick={e => window.close()} href="#">Close</a>)
            }
    }

    const showUserNotes = () => {
        axios.get(`${apiRoot}submissions/freelancer/api/freelance-project-users/current-user/kpis`, { headers }).then(res => {
            console.log(res.data.kpis)

            if (res.data.kpis == null) res.data.kpis = [];
            if (res.data.userNotes == null) res.data.userNotes = [];
            var messageList = "";
            if (res.data.kpis.length != 0) messageList = "<div class='dialogHeader'>Recent Project Based Comments</div>";
            for (var i = 0; i < res.data.kpis.length; i++) {
                messageList += "<div style='margin-top:20px;'><span>Date: <b>" + moment(res.data.kpis[i]["created_at"]).format("lll") + "</b></span><br/>Remarks: " + res.data.kpis[i]["remarks"] + "<br/>Rank: " + res.data.kpis[i]["average_rank"] + "</div>";
            }

            if (res.data.userNotes.length != 0) messageList += "<div class='dialogHeader'>General Notes From Managers</div>";
            for (var i = 0; i < res.data.userNotes.length; i++) {
                messageList += "<div style='margin-top:20px;'><span>Date: <b>" + moment(res.data.userNotes[i]["createdAt"]).format("lll") + "</b></span><br/>Remarks: " + res.data.userNotes[i]["notes"] + "<br/>Rank: " + res.data.userNotes[i]["sentiment"] + "</div>";
            }

            if (messageList.length == 0) messageList = "<div>No remarks found for the user.</div>";
            setUserNotesModalMessage(messageList);
            setUserNotesModalShow(true);
        });
    }
  

    var freelanceAuth = store("pubkit_auth");
    var headers = { ...freelanceAuth };

    useEffect(() => {
        console.log(params);
        var freelanceAuth = store("pubkit_auth");
        var headers = { ...freelanceAuth };

        console.log(freelanceAuth);

        if (params.page == "dashboard") {
            var apiUrl = `submissions/freelancer/api/project/outsourced?page=0&type=Completed`;
            console.log(headers);
            restFul.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                setProjects(res.data);
            });

            var apiUrl = `submissions/freelancer/api/user/info?fuzzyId=` + urlParams["fuzzyId"];
            console.log(headers);
            restFul.get(apiUrl, { headers }).then(res => {
                if (res.data["application"] == null) res.data["application"] = {};
                if (res.data["user"] == null) res.data["user"] = {};
                urlParams["fuzzyId"] = res.data["user"]["fuzzyId"];
                console.log(res.data);
                setUserInfo(res.data);
            });

            var apiUrl = `${apiRoot}submissions/freelancer/api/project/outsourced?page=0&type=completed-projects`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                setProjects(res.data);
            });

        }

        if (params.page == "score-card") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/freelance-project/${urlParams["fuzzy-id"]}/score`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                setUserScore(res.data);
            });
        }

        if (params.page == "active-projects") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/project/outsourced?page=0`;//&type=Assigned
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                res.data = formatDate(res.data, "dueDate");
                res.data.forEach(function(value, index){
                    if(value["freelancerDecision"]=="QUERIED")
                    {
                        value["responded"] = "NO";
                    }
                    else
                    {
                        value["responded"] = "YES";
                    }
                });
                setProjects(res.data);
            });
        }
        if (params.page == "work-orders") {
            var findSupplierUrl = `${reportApiRoot}reportsmicro/reports/approved_queries/supplier-from-user`;
            axios.post(findSupplierUrl,{where: {}}, { headers }).then(res => {
                console.log(res.data)
                setSupplier(res.data["id"]);
            });
            var apiUrl = `${reportApiRoot}reportsmicro/reports/approved_queries/fetch-project-work-orders`;//&type=Assigned
            console.log(headers);
            axios.post(apiUrl,{}, { headers }).then(res => {
                console.log(res.data);
                let workOrderList = res.data.filter((e)=> { return e.status === "PDF GENERATED" && e.supplier_id == supplier}) // && e.supplier_id == supplier
                console.log(workOrderList)
                setWorkOrders(workOrderList);
            });
        }
        if (params.page == "completed-projects") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/project/outsourced?page=0&type=completed-projects`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                res.data = formatDate(res.data, "dueDate");
                setProjects(res.data);
            });
        }

        if (params.page == "documents") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/user/documents?fuzzyId=${urlParams["fuzzyId"]}`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setUserDocuments(res.data);
            });
        }


    }, []);





    const setUnavailability = (values) => {
        restFul.post(`submissions/freelancer/api/user/unavailability`, { "fromDate": values[0].format('YYYY-MM-DD'), "toDate": values[1].format('YYYY-MM-DD') }, { headers }).then(res => {
            console.log(res.data);
            window.location.href = window.location.href;
        });
    }

    const changeCurrentObject = (record) => {
        setCurrentObject(record);
        console.log(record);
        setProjectStatusModal(true);
    }


    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    
    const uploadFile = (file) => {
        if(file==null) {
            message.error("No file is selected"); 
            return;
        }
        message.info("Please wait while upload is in progress");
        console.log(file);
        const filePath = "/uploads/user_documents/" + new Date().getTime() + "/" + file.name;
        var uploadParams = { Key: filePath };
        uploadParams.Body = file;
        uploadParams["filePath"] = filePath;
        uploadParams["fileName"] = file.name;
        uploadParams["size"] = file.size;
        uploadParams["type"] = file.type;
        uploadParams["documentType"] = theId;
        uploadParams["status"] = "submitted";
        uploadParams["fuzzyId"] = urlParams['fuzzyId'];

        myBucket.upload(uploadParams, function (err, data) {
            if (err) {
                console.log("Error", err);
            } if (data) {
                console.log("Upload Success", data.Location);
                var freelanceAuth = store("pubkit_auth");
                var headers = { ...freelanceAuth };
                axios.post(`${apiRoot}submissions/freelancer/api/upload/file`, uploadParams, { headers }).then(res => {
                    console.log(res.data)
                    window.location.href = window.location.href;
                });
            }
        });
    }


    const isApprovedFreelancer = () => {
        var freelanceAuth = store("pubkit_auth");
        var headers = { ...freelanceAuth };

        var apiUrl = `${apiRoot}submissions/freelancer/api/is-approved`;
        axios.get(apiUrl, { headers }).then(res => {
            console.log(res.data)
        });
    }

    const freelancerInvitationResponse = (values) => {
        console.log(values);
        console.log(currentObject);
        restFul.put(`submissions/freelancer/api/freelance-project-users/${currentObject.freelanceProjectUserFuzzyId}/in-app-decision`, values, { headers }).then(res => {
            console.log(res.data);
            //window.location.href = window.location.href;
        });
    }

    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));



    const menuClicked = (e) => {
        console.log(e);
        window.location.href = '/freelancer/' + e.key;
    };




    return (
        <Layout style={{ minWidth: "100%", height: '100vh', position: 'fixed' }}>
            <Layout className="site-layout">
                <Sider trigger={null} collapsible collapsed={collapsed} width={250} height={1000}>
                <div><img width="250" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" /></div>
                    <Menu
                        defaultOpenKeys={["application"]}
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={params['page']}
                        onClick={(e) => menuClicked(e)}
                        items={menuItems}
                    />
                </Sider>
                <Content style={{ margin: '10px 16px', padding: 0, overflow: "scroll" }}>

                    {(params.page == "dashboard") && (renderDashboard())}
                    {(params.page == "active-projects") && (renderActiveProjects())}
                    {(params.page == "completed-projects") && (renderCompletedProjects())}
                    {(params.page == "work-orders") && (renderWorkOrders())}
                    {(params.page == "score-card") && (renderScoreCard())}
                    {(params.page == "documents") && (renderDocuments())}
                    {(params.page == "application") && (renderApplication())}

                </Content>


                <Modal visible={projectStatusModal} title="Change Status" footer={null} onCancel={(e) => setProjectStatusModal(false)}>
                    <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={freelancerInvitationResponse} autoComplete="off">

                        <Form.Item label="Decision" name="freelancerDecision">
                            <Select style={{ width: 120 }} >
                                <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
                                <Select.Option value="REJECTED">REJECTED</Select.Option>
                                <Select.Option value="COMMENT">COMMENT</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Actual Budget" name="remarks">
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>



                <Modal title="Recent Remarks" width={1000} visible={userNotesModalShow} onCancel={(e) => setUserNotesModalShow(false)} footer={[<Button key="back" onClick={(e) => setUserNotesModalShow(false)}>Close</Button>]}>
                    <div dangerouslySetInnerHTML={{ __html: userNotesModalMessage }} />
                </Modal>
            </Layout>
            <Footer style={{ textAlign: 'center' }}><div className="myfooter"><a href="https://docs.google.com/document/d/e/2PACX-1vSssehMeX9FNAP534Q-ilH4XaI-wc__B5ASKIsGye90sV3YGatOhuP-KjDwPymJQ6o5Bpiu6VyNCsmY/pub">Terms and Conditions</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://docs.google.com/document/d/e/2PACX-1vSR8nGaX8FiG9JNheeZG6-4INv9vPh1uStfYqEomhlliL1hCiRFgR2ZCFVVUGMebLmIn7dgi8z6GMob/pub">Privacy Policy</a> <span style={{color:"#FFFFFF"}}>|</span> <a href="https://newgen.co/aboutus">Newgen KnowledgeWorks (A Unit of Newgen DigitalWorks Pvt Ltd).

</a></div></Footer>
        </Layout>
    );


    function renderDashboard() {

        isApprovedFreelancer();


        const deleteUnavailability = (record) => {
            restFul.delete(`submissions/freelancer/api/user/unavailability/delete/` + record.id, { headers }).then(res => {
                window.location.href = window.location.href;
            });
            console.log(record);
        }

        return (
            <div>

                {(userInfo.application !== undefined) && (
                    <div>

                        <Card title="Freelancer Details" bordered={false} style={{ width: 800 }}>

                            <Row gutter={[16, 16]}>
                                <Col className="gridPadding" span={12}>Name: {userInfo.user["fullName"]}</Col>
                                <Col className="gridPadding" span={8}>Email: {userInfo.user["email"]}</Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col className="gridPadding" span={12}>Notes: <Button icon={<CommentOutlined />} type="link" onClick={(e) => showUserNotes()}></Button></Col>
                                <Col className="gridPadding" span={12}>Joined At: {(userInfo.user["createdAt"]!=null) && moment(userInfo.user["createdAt"]).format("lll")}</Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col className="gridPadding" span={12}>NDA Signed: {((userInfo.application["ndaApprovedBy"] != null) ? "YES" : "NO" )}</Col>
                                <Col className="gridPadding" span={12}>TAX Form Submitted: {((userInfo.application["taxFormApprovedBy"]!=null) ? "YES" : "NO" )}</Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col className="gridPadding" span={12}>Country: {userInfo.application["country"]}</Col>
                            </Row>

                            <table className="table table-bordered">
                                <thead>
                                    <tr><th>Service Application</th><th>Status</th></tr>
                                </thead>
                                <tbody>
                                    {userInfo.userSkills.map(eachSkill =>
                                        <tr><td>{eachSkill["name"]}</td><td>{eachSkill["status"]}</td></tr>
                                    )}
                            </tbody>
                            </table>
                            

                        </Card>

                        <Title style={{marginTop:50}} level={5}>Unavailability</Title>
                        <Form>
                            <Form.Item name="fromDate">
                                <RangePicker
                                    placeholder={['Start month', 'End month']}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => setUnavailability(e)} />
                            </Form.Item>
                        </Form>
                        <Table rowKey="id" style={{width:800}} pagination={false} columns={[
                            {
                                title: 'Date From',
                                dataIndex: 'fromDate'
                            },
                            {
                                title: 'Date To',
                                dataIndex: 'toDate'
                            },
                            {
                                title: 'Created At',
                                render: (record) => {
                                    return (
                                        <span>{moment(record["createdAt"]).format("lll")}</span>
                                    )
                                }
                            },
                            {
                                title: 'Remove',
                                render: (record) => {
                                    return (
                                        <span><Button icon={<DeleteOutlined />} onClick={(e) => deleteUnavailability(record)}></Button></span>
                                    );
                                }
                            }
                        ]} dataSource={userInfo["user_unavail"]} />
                        <p><small>Users can save the unavailability dates. This is to ensure that the team will not try to reach you during this time.</small></p>

                    </div>
                )}

            </div>
        )
    }

    function renderApplication() {
        //window.location.href = "/freelancer/application";
    }




    function renderActiveProjects() {
        isApprovedFreelancer();



        const projectFileUpload = (record) => {
            console.log(record)
            axios.get(`${apiRoot}submissions/freelancer/api/files/list/` + record.projectId + "/task/" + record.taskId, { headers }).then(res => {
                console.log(res.data)
                setProjectFiles(res.data);
            });

            setCurrentObject(record);
            setShowProjectFileUpload("block");
        }
        
        return (
            <div>
                <Table rowKey="fuzzyId" columns={[
                    {
                        title: 'Project Name',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Freelancer Response',
                        dataIndex: 'freelancerDecision'
                    },
                    {
                        title: 'Confirmed',
                        dataIndex: 'responded'
                    },
                    {
                        title: 'Created At',
                        render: (record) => (
                            <span>{moment(record.createdAt).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Due to Newgen',
                        render: (record) => (
                            <span>{moment(record.projectDueDate).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Sent',
                        render: (record) => (
                            <span>{moment(record.createdAt).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Newgen Contact',
                        dataIndex: 'newgenContact'
                    },
                    {
                        title: 'Pay',
                        render: (record) => (
                            <span>{record.budgetMin} {record.currency}</span>
                        )
                    },
                    {
                        title: 'Files',
                        render: (value) => (
                            <Button onClick={(e) => projectFileUpload(value)}><CloudUploadOutlined /></Button>
                        )
                    }
                ]} dataSource={projects} />


                <div style={{ "display": showProjectFileUpload }}>
                    <Card title="Project Files" bordered={false} style={{ width: 800 }}>
                        <Table rowKey={record => record.id} columns={[
                            {
                                title: 'Name',
                                dataIndex: 'filename'
                            },
                            {
                                title: 'Created At',
                                render: (record) => (
                                    <span>{moment(record.createdAt).format("lll")}</span>
                                )
                            },
                            {
                                title: 'Download',
                                render: (value) => (
                                    <Button onClick={(e) => downloadS3File(value)}><CloudDownloadOutlined /></Button>
                                )
                            }
                        ]} dataSource={projectFiles} />
                    </Card>
                </div>
                <div style={{ height: 10000 }}></div>
            </div>
        )

    }


    function downloadWorkOrderPDF(record){
        if (record.filePath == undefined) record["filePath"] = record["work_order_file_path"];
            const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5) });
            window.open(url, '_blank').focus();
    }
    function downloadSignedWOPDF(record){
        if (record.filePath == undefined) record["filePath"] = record["signed_work_order_file_path"];
            const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5) });
            window.open(url, '_blank').focus();
    }
    function downloadInvoicePDF(record){
        if (record.filePath == undefined) record["filePath"] = record["invoice_file_path"];
            const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5) });
            window.open(url, '_blank').focus();
    }
    function uploadSignedWO(file){
        if(file==null) {
            message.error("No file is selected"); 
            return;
        }
        message.info("Please wait while upload is in progress");
        console.log(file);
        const filePath = "/uploads/signed_wo/" + new Date().getTime() + "/" + file.name;
        var uploadParams = { Key: filePath };
        uploadParams.Body = file;
        uploadParams["filePath"] = filePath;
        uploadParams["fileName"] = file.name;
        uploadParams["size"] = file.size;
        uploadParams["type"] = file.type;
        uploadParams["documentType"] = theId;
        uploadParams["status"] = "submitted";
        uploadParams["fuzzyId"] = urlParams['fuzzyId'];
        myBucket.upload(uploadParams, function (err, data) {
            if (err) {
                console.log("Error", err);
            } if (data) {
                console.log("Upload Success", data.Location);
                var userID = store("pubkit_user")["id"];
                var headers = { ...freelanceAuth };
                var payload = {
                    "signed_work_order_file_path": filePath,
                    "user_id": userID
                }
                axios.put(`${apiRoot}office/api/accounts/project-work-orders/${workOrderID}`, payload, { headers }).then(res => {
                    console.log(res.data)
                    //window.location.href = window.location.href;
                });
            }
        });
    }

    function uploadInvoice(file){
        if(file==null) {
            message.error("No file is selected"); 
            return;
        }
        message.info("Please wait while upload is in progress");
        console.log(file);
        const filePath = "/uploads/signed_wo/" + new Date().getTime() + "/" + file.name;
        var uploadParams = { Key: filePath };
        uploadParams.Body = file;
        uploadParams["filePath"] = filePath;
        uploadParams["fileName"] = file.name;
        uploadParams["size"] = file.size;
        uploadParams["type"] = file.type;
        uploadParams["documentType"] = theId;
        uploadParams["status"] = "submitted";
        uploadParams["fuzzyId"] = urlParams['fuzzyId'];
        myBucket.upload(uploadParams, function (err, data) {
            if (err) {
                console.log("Error", err);
            } if (data) {
                console.log("Upload Success", data.Location);
                var userID = store("pubkit_user")["id"];
                var headers = { ...freelanceAuth };
                var payload = {
                    "invoice_file_path": filePath,
                    "user_id": userID
                }
                axios.put(`${apiRoot}office/api/accounts/project-work-orders/${workOrderID}`, payload, { headers }).then(res => {
                    console.log(res.data)
                    //window.location.href = window.location.href;
                });
            }
        });
    }
    function renderScoreCard() {
        isApprovedFreelancer();


        const columns = [
            {
                key: "name",
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key: "name",
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key: "user_rank",
                title: 'User Rank',
                dataIndex: 'user_rank'
            }
        ];


        return (
            <Table rowKey="id" columns={columns} dataSource={userScore} />
        )
    }
    function renderWorkOrders(){
        isApprovedFreelancer();

        return (
            <div>
                <h4>Work Orders</h4>
                <Table rowKey="id" columns={[
                    {
                        title: 'S/N',
                        key:"index",
                        render:(value, item, index) =>{ return index+1}
                    },
                    {
                        title: 'Name',
                        dataIndex: 'ititle'
                    },
                    {
                        title: "WO ID",
                        dataIndex: 'wo_id'
                    },
                    {
                        title: 'Company',
                        dataIndex: 'company'
                    },
                    {
                        title: 'Division',
                        dataIndex: 'division_name'
                    },
                    {
                        title: 'Category',
                        dataIndex: 'category'
                    },
                    {
                        title: 'Currency',
                        dataIndex: 'currency'
                    },
                    {
                        title: 'INR',
                        dataIndex: 'gross_total_inr'
                    },
                    {
                        title: "Download WO PDF",
                        render: (value, item, index)=> {
                            return <div class="hover-download-interact"><DownloadOutlined ></DownloadOutlined> Download PDF</div>
                        },
                        onCell: (record, rowIndex) => {
                            return {
                                onClick: (ev) => {
                                    downloadWorkOrderPDF(record)
                                },
                            };
                        }
                    },
                    {
                        title: "Upload Signed WO",
                        render: (value, item, index)=> {
                            return value["signed_work_order_file_path"]==null?<div class="hover-upload-interact"><input type="file" onChange={handleFileInput} style={{width:250, marginLeft:0, background:"white"}}/><Button onClick={() => uploadSignedWO(selectedFile)}   type="primary" style={{marginLeft: 0, marginTop:0,}}>UPLOAD</Button></div>: <div class="hover-download-interact" onClick={downloadSignedWOPDF(value)}><DownloadOutlined ></DownloadOutlined> Download Signed WO</div>
                            
                        },
                        onCell: (record, rowIndex) => {
                            return {
                                onClick: (ev) => {
                                    setWorkOrderID(record["fuzzy_id"])
                                },
                            };
                        }
                    },
                    {
                        title: "Upload WO Invoice",
                        render: (value, item, index)=> {
                            return value["invoice_file_path"]==null?<div class="hover-upload-interact"><input type="file" onChange={handleFileInput} style={{width:250, marginLeft:0, background:"white"}}/><Button onClick={() => uploadInvoice(selectedFile)}   type="primary" style={{marginLeft: 0, marginTop:0,}}>UPLOAD</Button></div>: <div class="hover-download-interact" onClick={downloadInvoicePDF(value)}><DownloadOutlined ></DownloadOutlined> Download Invoice</div>
                        },
                        onCell: (record, rowIndex) => {
                            return {
                                onClick: (ev) => {
                                    setWorkOrderID(record["fuzzy_id"])
                                },
                            };
                        }
                    }
                ]} dataSource={workOrders} />
                <div style={{ height: 10000 }}></div>
            </div>
        )

    }
    function renderCompletedProjects() {
        isApprovedFreelancer();

        return (
            <div>
                
                <Table rowKey="id" columns={[
                    {
                        title: 'Name',
                        dataIndex: 'id'
                    },
                    {
                        title: 'Score',
                        dataIndex: 'score'
                    },
                    {
                        title: 'Remarks',
                        dataIndex: 'remarks'
                    },
                    {
                        title: 'Due',
                        dataIndex: 'dueDate'
                    },
                    {
                        key: "created_at",
                        title: 'Created At',
                        dataIndex: 'created_at'
                    }
                ]} dataSource={projects} />
                <div style={{ height: 10000 }}></div>
            </div>
        )

    }



    function renderDocuments() {

        const fileUploadType = (e) => {
            console.log(e);
            setTheId(e);
        }

        const deleteFile = (record) => {
            axios.delete(`${apiRoot}submissions/freelancer/api/file/delete/` + record.fuzzyId, { headers }).then(res => {
                message.info(res.data["message"]);
                setTimeout(function () { window.location.href = window.location.href }, 1000);
            });
        }

        const downloadS3File = (record) => {
            const url = myBucket.getSignedUrl('getObject', { Key: record.filePath, Expires: (60 * 5) });
            window.location.href = url;
        }

        const changeUserDocumentStatus = (values) => {
            console.log(values, currentObject);
            axios.put(`${apiRoot}submissions/freelancer/api/update/file/` + currentObject.fuzzyId, { "status": values.status }, { headers }).then(res => {
                 window.location.href = window.location.href;
            });
        }

        const changeUserDocumentStatusModal = (record) =>
        {
            setCurrentObject({...record});
            setUserDocumentStatusModal(true);
        }

        return (
            <div>
                <Table rowKey="id" pagination={false} columns={[
                    {
                        title: 'Name',
                        render: (record) => (
                            <span className='App-link'><a onClick={(e) => downloadS3File(record)}>{record.fileName}</a></span>
                        )
                    },
                    {
                        title: 'Document',
                        dataIndex: 'documentType'
                    },
                    {
                        title: 'Status',
                        render: (record) => (
                            <span className='App-link'>{(isManager) ? <a onClick={(e) => changeUserDocumentStatusModal(record)}>{record.status}</a> : record.status }</span>
                        )
                    },
                    {
                        title: 'Uploaded At',
                        dataIndex: 'createdAt',
                        render: (value) => (
                            <span>{moment(value).format("lll")}</span>
                        )
                    },
                    {
                        title: 'Delete',
                        render: (record) => (
                            <span className='App-link'><a onClick={(e) => deleteFile(record)}><DeleteOutlined /></a></span>
                        )
                    }
                ]} dataSource={userDocuments} />

                <Select onChange={(e) => fileUploadType(e)} style={{ width: 200, marginTop:20}} rules={[{ required: true }]} placeholder="Select File Type">
                        <Option value="NDA">NDA</Option>
                        <Option value="SAMPLES">SAMPLES</Option>
                        <Option value="PORTFOLIO">PORTFOLIO</Option>
                        <Option value="CV">CV</Option>
                        <Option value="TAX_FORM">TAX_FORM</Option>
                        <Option value="MISC">MISC</Option>
                    </Select>
                    <input type="file" onChange={handleFileInput} style={{width:250, marginLeft:30, background:"white"}}/>
                    <Button onClick={() => uploadFile(selectedFile)}   type="primary" style={{marginLeft: 30, marginTop:-8,}}>UPLOAD</Button>
                  
               
                <div style={{ height: 10000 }}></div>

                <Modal visible={userDocumentStatusModal} title="Change Status" footer={null} onCancel={(e) => setUserDocumentStatusModal(false)}>
                    <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={(e) => changeUserDocumentStatus(e)} autoComplete="off">
                        <Form.Item label="Decision" name="status">
                            <Select style={{ width: 120 }} >
                                <Select.Option value="APPROVED">APPROVED</Select.Option>
                                <Select.Option value="REJECTED">REJECTED</Select.Option>
                                <Select.Option value="SUBMITTED">SUBMITTED</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>

                </Modal>

            </div>
        )
    }



};